import { autorun, makeAutoObservable, reaction, toJS } from 'mobx'
import moment from 'moment'
import { message } from 'antd'
import { Locale } from 'antd/lib/locale-provider'
import heIL from 'antd/lib/locale/he_IL'
import enUS from 'antd/lib/locale/en_US'
import { t } from 'i18next'
import i18n from 'i18n/i18n'
import { getCurrentColorScheme } from 'themes'
import { api, settingsLists, ErrorsMessages } from 'config'
import * as usersActions from 'actions/users'
import * as settingsActions from 'actions/settings'
import SecureLS from 'secure-ls'
import {
  ISettings,
  TLocaleName,
  TLocaleNameStrict,
  ICredentials,
  TCategoriesNames,
  ICategories,
  ICategory,
  ILoginResponseDTO,
} from 'models'
import messagesStore from './Messages'

const doc = document.querySelector('HTML') as Element

export const secureLS = new SecureLS({ encodingType: 'aes' })

class SettingsStore {
  constructor() {
    makeAutoObservable(this)
  }

  settings: ISettings = {
    darkScheme: undefined,
    locale: undefined,
    isMenuCollapsed: false,
    passwordResetEmail: null,
    picCategories: [],
    familyMembers: [],
    friends: [],
    circles: [],
    religion: [],
    isAuth: false,
    wrongAttempt: false,
    passwordReseted: false,
    usersFilter: [],
    categoriesUpdateSuccess: false,
    categoriesUpdateFail: false,
    isInitialized: false,
    errorMessage: '',
  }

  checkAuth = async (credentials: ICredentials): Promise<void> => {
    try {
      const data: ILoginResponseDTO = await usersActions.login(credentials)
      secureLS.set('token', data.access_token)
      api.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${data.access_token}`
      this.settings.isAuth = true
    } catch (err) {
      console.error(err)
      this.settings.errorMessage = (err as Error).message
      this.settings.wrongAttempt = true
    }
  }

  setIsInitialized = (): void => {
    this.settings.isInitialized = true
  }

  resetPassword = async (email: { email: string }): Promise<void> => {
    try {
      await usersActions.resetPassword(email)
      this.settings.passwordResetEmail = email.email
      this.settings.passwordReseted = true
    } catch (err) {
      console.error(err)
      this.settings.wrongAttempt = true
    }
  }

  unsetPasswordReseted = (): void => {
    this.settings.passwordReseted = false
  }

  fillSettingsList = (category: ICategories, data: ICategory[]): void => {
    this.settings[category.title] = data
  }

  unsetAuth = (): void => {
    this.settings.isAuth = false
    secureLS.remove('token')
    this.settings.passwordResetEmail = null
    delete api.defaults.headers.common['Authorization']
  }

  setAuth = (): void => {
    this.settings.isAuth = true
  }

  unsetWrongAttempt = (): void => {
    this.settings.wrongAttempt = false
  }

  collapseMenu = (): void => {
    this.settings.isMenuCollapsed = true
  }

  uncollapseMenu = (): void => {
    this.settings.isMenuCollapsed = false
  }

  toggleMenu = (): void => {
    this.settings.isMenuCollapsed = !this.settings.isMenuCollapsed
  }

  setDarkScheme = (state: boolean): void => {
    this.settings.darkScheme = state
  }

  setLocale = (name: TLocaleNameStrict): void => {
    this.settings.locale = name
  }

  updateCategories = async (
    settingName: TCategoriesNames,
    data: {}
  ): Promise<void> => {
    const settingEndpoint = settingsLists.find(
      (category: ICategories) => category.title === settingName
    )?.endpoint

    const values: ICategory[] = Object.entries(data).map(item => {
      return { id: +item[0], name: item[1] as string, description: '' }
    })

    try {
      this.settings[settingName] = await settingsActions.saveCategoriesList(
        settingEndpoint as string,
        values
      )
      this.settings.categoriesUpdateSuccess = true
    } catch (err) {
      console.error(err)
      this.settings.categoriesUpdateFail = true
    }
  }

  unsetCategoriesUpdateSuccess = (): void => {
    this.settings.categoriesUpdateSuccess = false
  }

  unsetCategoriesUpdateFail = (): void => {
    this.settings.categoriesUpdateFail = false
  }

  setErrorMessage = (text: string): void => {
    this.settings.errorMessage = text
  }

  get antdDirection(): 'ltr' | 'rtl' {
    switch (this.settings.locale) {
      case 'en':
        return 'ltr'
      case 'he':
        return 'rtl'
      default:
        return 'ltr'
    }
  }

  get antdLocale(): Locale {
    switch (this.settings.locale) {
      case 'en':
        return enUS
      case 'he':
        return heIL
      default:
        return enUS
    }
  }
}

const settingsStore = new SettingsStore()

function checkLocalStorageColor(darkScheme: boolean | undefined): void {
  if (secureLS.getAllKeys().includes('colorScheme')) {
    if (darkScheme === undefined) {
      settingsStore.setDarkScheme(secureLS.get('colorScheme') === 'dark')
      return
    }
    if (secureLS.get('colorScheme') !== (darkScheme && 'dark') || 'light') {
      secureLS.set('colorScheme', (darkScheme && 'dark') || 'light')
    }
  } else if (!secureLS.get('colorScheme')) {
    settingsStore.setDarkScheme(getCurrentColorScheme() === 'dark')
    secureLS.set('colorScheme', getCurrentColorScheme())
  }
}

function checkLocalStorageLocale(locale: TLocaleName): void {
  if (secureLS.getAllKeys().includes('locale')) {
    if (locale === undefined) {
      settingsStore.setLocale(secureLS.get('locale') as TLocaleNameStrict)
      return
    }
    if (secureLS.get('locale') !== locale) {
      secureLS.set('locale', locale as TLocaleNameStrict)
    }
  } else if (!secureLS.get('locale')) {
    settingsStore.setLocale(i18n.resolvedLanguage as TLocaleNameStrict)
    secureLS.set('locale', i18n.resolvedLanguage)
  }
}

autorun(() => {
  checkLocalStorageColor(settingsStore.settings.darkScheme)
})

autorun(() => {
  checkLocalStorageLocale(settingsStore.settings.locale)
  moment.locale(settingsStore.settings.locale)
  i18n.changeLanguage(settingsStore.settings.locale)
  doc.setAttribute('lang', settingsStore.settings.locale as TLocaleNameStrict)
})

reaction(
  () => settingsStore.settings.wrongAttempt,
  isWrong => {
    if (isWrong) {
      message.warning(
        `${t('wrong_credentials')}. ${t(
          ErrorsMessages[
            settingsStore.settings.errorMessage as keyof typeof ErrorsMessages
          ]
        )}`,
        2
      )
      settingsStore.unsetWrongAttempt()
    }
  }
)

reaction(
  () => settingsStore.settings.passwordReseted,
  isReseted => {
    if (isReseted) {
      message.success(
        `${t('password_reset_success')} ${toJS(
          settingsStore.settings.passwordResetEmail
        )}`,
        3
      )
      settingsStore.unsetPasswordReseted()
    }
  }
)

reaction(
  () => settingsStore.settings.isAuth,
  isAuth => {
    if (isAuth) {
      settingsLists.forEach(async (category: ICategories) => {
        try {
          const data: ICategory[] = await settingsActions.getCategoriesList(
            category.endpoint
          )
          settingsStore.fillSettingsList(category, data)
        } catch (err) {
          console.error(err)
        }
      })
    }
  }
)

reaction(
  () => settingsStore.settings.isAuth,
  isAuth => {
    if (isAuth) {
      messagesStore.updateMessagesInfo()
    }
  }
)

reaction(
  () => settingsStore.settings.categoriesUpdateFail,
  fail => {
    if (fail) {
      message.warning(t('category_save_fail'), 2)
      settingsStore.unsetCategoriesUpdateFail()
    }
  }
)

reaction(
  () => settingsStore.settings.categoriesUpdateSuccess,
  success => {
    if (success) {
      message.success(t('category_save_success'), 2)
      settingsStore.unsetCategoriesUpdateSuccess()
    }
  }
)

export default settingsStore
