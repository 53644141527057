import React from 'react'
import { observer } from 'mobx-react-lite'
import { Form, Typography, Input, Button, Layout, Col, Row, Modal } from 'antd'
import { Navigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ICredentials, IStore } from 'models'

import ConfigPanel from 'components/ConfigPanel'

import { useStore } from 'stores'

import logo from 'sources/images/logo.png'
import styles from './styles.module.scss'

const { Header, Content } = Layout

interface ILocationState {
  from: Location
}

function LoginPage(): React.ReactElement {
  const { settingsStore } = useStore()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { state } = useLocation()

  let fromPage = !!state ? (state as ILocationState).from.pathname : '/users'

  const onSubmit = (values: IStore): void => {
    settingsStore.checkAuth(values as unknown as ICredentials)
  }

  const passwordResetHandler = (email: string): void => {
    Modal.confirm({
      title: t('reset_confirmation'),
      content: email,
      okText: t('ok'),
      cancelText: t('cancel'),
      onOk() {
        settingsStore.resetPassword({ email })
      },
      onCancel() {},
    })
  }

  return settingsStore.settings.isAuth ? (
    <Navigate to={fromPage} replace />
  ) : (
    <Layout className={styles.layout}>
      <Header className={styles.header}>
        <Row align="middle" justify="space-between">
          <Col>
            <img src={logo} alt="SOULO logo" />
          </Col>
          <Col>
            <ConfigPanel />
          </Col>
        </Row>
      </Header>
      <Content className={styles.container}>
        <div className={styles.login}>
          <Typography.Title level={3}>{t('welcome')}SOULO</Typography.Title>
          <Typography.Title level={4}>{t('sign')}</Typography.Title>
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onSubmit}
            autoComplete="off"
          >
            <Form.Item
              label={t('email')}
              name="email"
              rules={[
                { type: 'email', required: true, message: t('error_email') },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('password')}
              name="password"
              rules={[{ required: true, message: t('error_password') }]}
            >
              <Input.Password />
            </Form.Item>
            <Row justify="space-between">
              <Form.Item>
                <Button
                  size="small"
                  type="link"
                  htmlType="button"
                  onClick={() => {
                    passwordResetHandler(form.getFieldValue('email'))
                  }}
                >
                  {t('reset_password')}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {t('sign')}
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}

export default observer(LoginPage)
