import React from 'react'
import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'
import { Layout } from 'antd'

import NavBar from 'components/NavBar'
import ConfigPanel from 'components/ConfigPanel'

import { useStore } from 'stores'

import styles from './styles.module.scss'

const { Content, Sider } = Layout

function Main(): React.ReactElement {
  const { settingsStore } = useStore()

  return (
    <Layout className={styles.layout}>
      <Sider
        collapsible
        breakpoint="md"
        collapsedWidth="0"
        trigger={null}
        collapsed={settingsStore.settings.isMenuCollapsed}
        onBreakpoint={broken => {
          broken ? settingsStore.collapseMenu() : settingsStore.uncollapseMenu()
        }}
      >
        <div className={styles.config_panel_wrapper}>
          <ConfigPanel />
        </div>
        <NavBar />
      </Sider>
      <Content>
        <Outlet />
      </Content>
    </Layout>
  )
}

export default observer(Main)
