import React from 'react'
import { observer } from 'mobx-react-lite'
import { Switch } from 'antd'

import { useStore } from 'stores'

import moon from 'sources/images/moon.svg'
import sun from 'sources/images/sun.svg'
import styles from './styles.module.scss'

function SchemeToggler(): React.ReactElement {
  const { settingsStore } = useStore()

  function changeHandler(value: boolean): void {
    settingsStore.setDarkScheme(value)
  }

  return (
    <span className={styles.scheme_toggler}>
      <Switch
        checkedChildren={
          <img src={sun} height="24" width="24" alt="light scheme icon" />
        }
        unCheckedChildren={
          <img src={moon} height="24" width="24" alt="dark scheme icon" />
        }
        defaultChecked={settingsStore.settings.darkScheme}
        onChange={value => changeHandler(value)}
      />
    </span>
  )
}

export default observer(SchemeToggler)
