import { reaction, makeAutoObservable } from 'mobx'
import { message } from 'antd'
import * as usersActions from 'actions/users'
import * as profilesActions from 'actions/profiles'
import {
  IUser,
  IAdmin,
  IUsersRequestOptions,
  IRequestParams,
  IUsersResponseDTO,
  IUserProfile,
  IGuestFollower,
} from 'models'
import { t } from 'i18next'
import {
  api,
  minAutoSearchLength,
  defaultUsersPageSize,
  ErrorsMessages,
} from 'config'

import settingsStore, { secureLS } from './Settings'

class UsersStore {
  constructor() {
    makeAutoObservable(this)
  }

  users: IUser[] = []
  needUpdate: boolean = false
  adminAddSuccess: boolean = false
  adminAddFail: boolean = false
  infoHasUpdated: boolean = false
  currentUser: IUser | null = null
  requestTotal: number | null = null
  pageSize: number = defaultUsersPageSize
  sortOrder: 'ASC' | 'DESC' | undefined = undefined
  roleFilter: 'User' | 'Admin' | undefined = undefined
  searchString: string | undefined = undefined
  searchText: string | undefined = undefined
  currentPage: number = 1
  guestProfile: IUserProfile | undefined = undefined
  guestFollowers: IGuestFollower[] = []

  setUsersRequestOptions = (params: IRequestParams): void => {
    this.sortOrder = params.sorting
    this.roleFilter = params.filter
    this.currentPage = params.toPage
    this.pageSize = params.pageSize
    this.needUpdate = true
  }

  setPageSize = (size: number): void => {
    this.pageSize = size
    this.currentPage = 1
    this.needUpdate = true
  }

  setInfo = (usersInfo: IUser[]): void => {
    this.users = usersInfo
  }

  setPaginationTotal = (total: number): void => {
    this.requestTotal = total
  }

  unsetInfo = (): void => {
    this.users = []
  }

  setSearchString = (searchString: string): void => {
    if (searchString === '') {
      this.searchString = undefined
    } else {
      this.searchString = searchString
    }
    this.currentPage = 1
    usersStore.needUpdate = true
  }

  unsetSearchString = (): void => {
    this.searchString = undefined
  }

  getGuestProfile = async (profileId: number): Promise<void> => {
    try {
      this.guestProfile = await profilesActions.getGuestProfile(profileId)
    } catch (err) {
      console.error(err)
    }
  }

  getFollowers = async (profileId: number): Promise<void> => {
    try {
      const profilesFollowers = await profilesActions.getProfilesFollowers(
        profileId
      )
      const usersFollowers = await profilesActions.getUsersFollowers(profileId)
      this.guestFollowers = Array.from(
        new Set(profilesFollowers.concat(usersFollowers))
      )
    } catch (err) {
      console.error(err)
    }
  }

  defineSearchString = (searchString: string): void => {
    this.searchText = searchString
    if (searchString.length < minAutoSearchLength) {
      if (this.searchString === undefined) {
        return
      } else {
        this.searchString = undefined
        this.currentPage = 1
        this.needUpdate = true
      }
    } else {
      this.searchString = searchString
      this.currentPage = 1
      this.needUpdate = true
    }
  }

  setNeedUpdate = (): void => {
    this.needUpdate = true
  }

  unsetNeedUpdate = (): void => {
    this.needUpdate = false
  }

  blockUser = async (user: IUser): Promise<void> => {
    try {
      await usersActions.blockUser(user)
      this.needUpdate = true
    } catch (err) {
      console.error(err)
    }
  }

  addAdmin = async (admin: IAdmin): Promise<void> => {
    try {
      await usersActions.addAdmin(admin)
      this.needUpdate = true
      this.adminAddSuccess = true
    } catch (err) {
      settingsStore.setErrorMessage((err as Error).message!)
      this.adminAddFail = true
    }
  }

  unsetAdminAddSuccess = (): void => {
    this.adminAddSuccess = false
  }

  unsetAdminAddFail = (): void => {
    this.adminAddFail = false
  }

  getUserInfo = async (userId: number): Promise<void> => {
    try {
      this.currentUser = await usersActions.fetchUserInfo(userId)
      this.infoHasUpdated = true
    } catch (err) {
      console.error(err)
    }
  }

  unsetInfoHasUpdated = (): void => {
    this.infoHasUpdated = false
    this.currentUser = null
  }

  blockProfile = async (profile: IUserProfile): Promise<void> => {
    try {
      await profilesActions.blockProfile(profile)
      this.needUpdate = true
    } catch (err) {
      console.error(err)
    }
  }

  checkLocalStorageToken = async (): Promise<void> => {
    if (secureLS.getAllKeys().includes('token')) {
      api.defaults.headers.common['Authorization'] = `Bearer ${secureLS.get(
        'token'
      )}`
      try {
        const response: IUsersResponseDTO = await usersActions.getUsers(
          this.usersRequestOptions
        )
        this.setInfo(response.data)
        this.setPaginationTotal(response.total)
        settingsStore.setAuth()
      } catch (err) {
        delete api.defaults.headers.common['Authorization']
        secureLS.remove('token')
        console.error(err)
      }
    }
    settingsStore.setIsInitialized()
  }

  get usersRequestOptions(): IUsersRequestOptions {
    return {
      params: {
        startFrom: (this.currentPage - 1) * this.pageSize,
        take: this.pageSize,
        fullNameSorting: this.sortOrder,
        role: this.roleFilter,
        search: this.searchString,
      },
    }
  }
}

const usersStore = new UsersStore()

reaction(
  () => settingsStore.settings.isAuth,
  isAuth => {
    if (isAuth) {
      usersStore.setNeedUpdate()
    }
  }
)

reaction(
  () => usersStore.needUpdate,
  async needUpdate => {
    if (needUpdate) {
      try {
        const response: IUsersResponseDTO = await usersActions.getUsers(
          usersStore.usersRequestOptions
        )
        usersStore.setInfo(response.data)
        usersStore.setPaginationTotal(response.total)
        usersStore.unsetNeedUpdate()
        if (usersStore.currentUser) {
          usersStore.getUserInfo(usersStore.currentUser.id)
        }
      } catch (err) {
        console.error(err)
        usersStore.unsetSearchString()
        usersStore.unsetNeedUpdate()
      }
    }
  }
)

reaction(
  () => usersStore.adminAddFail,
  fail => {
    if (fail) {
      message.warning(
        `${t('admin_add_fail')}. ${t(
          ErrorsMessages[
            settingsStore.settings.errorMessage as keyof typeof ErrorsMessages
          ]
        )}`,
        2
      )
      usersStore.unsetAdminAddFail()
    }
  }
)

reaction(
  () => usersStore.adminAddSuccess,
  success => {
    if (success) {
      message.success(t('admin_add_success'), 2)
      usersStore.unsetAdminAddSuccess()
    }
  }
)

export default usersStore
