import { createContext, useContext } from 'react'
import { configure, makeAutoObservable } from 'mobx'

import usersStore from './Users'
import settingsStore from './Settings'
import messagesStore from './Messages'

configure({ enforceActions: 'observed' })

class RootStore {
  constructor() {
    makeAutoObservable(this)
  }

  usersStore = usersStore
  settingsStore = settingsStore
  messagesStore = messagesStore
}

export const rootStore = new RootStore()

const StoreContext = createContext<RootStore>(rootStore)

export const useStore = (): RootStore => {
  const store = useContext(StoreContext)
  if (!store) {
    throw new Error('You have forgot to use StoreProvider, shame on you.')
  }
  return store
}
