import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher'
import { ConfigProvider, Spin } from 'antd'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import 'moment/locale/he'
import { themes } from 'themes'

import LoginPage from 'containers/Public/LoginPage'
import Users from 'containers/Main/Users'
import User from 'containers/Main/User'
import Settings from 'containers/Main/Settings'
import Messages from 'containers/Main/Messages'
import Main from 'containers/Main'
import PrivateRoute from 'components/PrivateRoute'

import { useStore } from 'stores'

import styles from './styles.module.scss'
import GuestPage from 'containers/Public/GuestPage'

function App(): React.ReactElement {
  const store = useStore()
  useEffect(() => {
    store.usersStore.checkLocalStorageToken()
  }, [])

  return (
    <ThemeSwitcherProvider
      defaultTheme={store.settingsStore.settings.darkScheme ? 'dark' : 'light'}
      themeMap={themes}
      insertionPoint={document.getElementById('inject-styles-here')}
    >
      <ConfigProvider
        direction={store.settingsStore.antdDirection}
        locale={store.settingsStore.antdLocale}
      >
        <div className={styles.app}>
          {store.settingsStore.settings.isInitialized ? (
            <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <Main />
                    </PrivateRoute>
                  }
                >
                  <Route index element={<Users />} />
                  <Route path="users" element={<Users />} />
                  <Route path="settings" element={<Settings />} />
                  <Route path="messages" element={<Messages />} />
                  <Route path="users/:userId" element={<User />} />
                  <Route path="*" element={<Users />} />
                </Route>
                <Route path="/login" element={<LoginPage />} />
                <Route path="profile/:profileId" element={<GuestPage />} />
              </Routes>
            </BrowserRouter>
          ) : (
            <Spin size="large" />
          )}
        </div>
      </ConfigProvider>
    </ThemeSwitcherProvider>
  )
}

export default observer(App)
