import { api, massagesEndpoint } from '../config'
import {
  TMessageStatus,
  IMessage,
  IMessagesRequestOptions,
  IMessageResponse,
} from '../models'

export async function getMessages(
  messagesRequestOptions: IMessagesRequestOptions
): Promise<IMessageResponse> {
  const { data, status } = await api.get(
    massagesEndpoint,
    messagesRequestOptions
  )
  if (status !== 200) {
    throw new Error(data.message || 'There was error fetching messages')
  }
  return data
}

export async function patchMessage(
  messageId: number,
  messageStatus: TMessageStatus
): Promise<IMessage> {
  const { data, status } = await api.patch(`${massagesEndpoint}/${messageId}`, {
    status: messageStatus,
  })
  if (status !== 200) {
    throw new Error(data.message || `Can't patch message`)
  }
  return data
}
