import { makeAutoObservable, reaction } from 'mobx'

import {
  IMessage,
  TMessageStatus,
  TMessageType,
  IShowingParams,
  TSorter,
  IMessagesRequestOptions,
} from 'models'
import { defaultMessagesPageSize, minAutoSearchLength } from 'config'
import * as messagesActions from '../actions/messages'

class MessagesStore {
  constructor() {
    makeAutoObservable(this)
  }
  messages: IMessage[] = []
  statusFilter: TMessageStatus[] | null = null
  typeFilter: TMessageType[] | null = null
  dateSorting: TSorter | null = undefined
  userFilter: string | undefined = ''
  searchText: string | undefined = undefined
  needUpdate: boolean = false
  currentPage: number = 1
  pageSize: number = defaultMessagesPageSize
  messagesTotal: number = 0
  messagesNew: number = 0
  editingMessage: IMessage | null = null

  setTableOptions = (options: IShowingParams): void => {
    this.typeFilter = options.typeFilter
    this.statusFilter = options.statusFilter
    this.dateSorting = options.dateSorting
    this.needUpdate = true
    this.currentPage = options.toPage
  }

  setMessages = (data: IMessage[]): void => {
    this.messages = data
  }

  setSearchString = (searchString: string): void => {
    if (searchString === '') {
      this.userFilter = undefined
    } else {
      this.userFilter = searchString
    }
  }

  updateMessagesInfo = async (): Promise<void> => {
    try {
      const { data, total } = await messagesActions.getMessages(
        this.messagesRequestOptions
      )
      this.setMessages(data)
      this.messagesTotal = total
      const { data: dataNew } = await messagesActions.getMessages({
        params: {
          statuses: ['new'],
        },
      })
      this.messagesNew = dataNew.length
    } catch (err) {
      console.error(err)
    } finally {
      this.needUpdate = false
    }
  }

  setPageSize = (size: number): void => {
    this.pageSize = size
  }

  setAcceptedStatus = async (): Promise<void> => {
    try {
      const response = await messagesActions.patchMessage(
        this.editingMessage?.id!,
        'accepted'
      )
      if (response) {
        this.setEditingMessage({
          ...this.editingMessage,
          ...response,
        })
      }
      this.needUpdate = true
    } catch (err) {
      console.error(err)
    }
  }

  patchMessage = async (status: TMessageStatus): Promise<void> => {
    try {
      await messagesActions.patchMessage(this.editingMessage?.id!, status)
      this.needUpdate = true
      this.editingMessage = null
    } catch (err) {
      console.error(err)
    }
  }

  setEditingMessage = (record: IMessage): void => {
    this.editingMessage = record
  }

  defineSearchString = (searchString: string): void => {
    this.searchText = searchString
    if (searchString.length < minAutoSearchLength) {
      if (this.userFilter === undefined) {
        return
      } else {
        this.userFilter = undefined
      }
    } else {
      this.userFilter = searchString
    }
  }

  unsetSearchString = (): void => {
    this.userFilter = undefined
  }

  setNeedUpdate = (): void => {
    this.needUpdate = true
  }

  get messagesRequestOptions(): IMessagesRequestOptions {
    return {
      params: {
        startFrom: (this.currentPage - 1) * this.pageSize,
        take: this.pageSize,
        order: this.dateSorting || undefined,
        searchQuery: this.userFilter || undefined,
        statuses: this.statusFilter || undefined,
        types: this.typeFilter || undefined,
      },
    }
  }

  get messagesToShow(): IMessage[] {
    return this.messages
  }
}

const messagesStore = new MessagesStore()

reaction(
  () => messagesStore.needUpdate,
  needUpdate => {
    if (needUpdate) {
      messagesStore.updateMessagesInfo()
    }
  }
)

reaction(
  () => messagesStore.userFilter,
  userFilter => {
    messagesStore.setNeedUpdate()
  }
)

reaction(
  () => messagesStore.editingMessage,
  editingMessage => {
    if (editingMessage) {
      if (editingMessage.status === 'new') {
        messagesStore.setAcceptedStatus()
      }
    }
  }
)

export default messagesStore
