import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { parsePhoneNumber } from 'libphonenumber-js'
import {
  Avatar,
  Button,
  Switch,
  Table,
  Tag,
  Input,
  Space,
  PageHeader,
  Tooltip,
  Form,
} from 'antd'
import { TablePaginationConfig } from 'antd/lib/table'
import { UserOutlined, UserAddOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { defaultUsersPageSize } from 'config'
import {
  IRole,
  IUser,
  IUserProfile,
  TFilter,
  TSorter,
  TSortOrder,
} from 'models'

import AddAdminDialog from 'components/AddAdminDialog'
import HeaderMenuIcon from 'components/HeaderMenuIcon'

import { useStore } from 'stores'

import styles from './styles.module.scss'

const { Search } = Input

function Users(): React.ReactElement {
  const { usersStore, settingsStore } = useStore()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const tableColumns = [
    {
      title: t('user'),
      width: 224,
      key: 'fullName',
      fixed: true,
      ellipsis: {
        showTitle: false,
      },
      render: (text: string, record: IUser) => (
        <Link to={`/users/${record.id}`} className={styles.link}>
          <div>
            <Space>
              <Avatar src={record?.avatarThumbnail} icon={<UserOutlined />} />
              <Tooltip
                placement="topLeft"
                title={
                  <>
                    {record.firstName} {record.lastName}
                  </>
                }
              >
                <span>
                  {record.firstName} {record.lastName}
                </span>
              </Tooltip>
            </Space>
          </div>
        </Link>
      ),
      sorter: true,
      sortOrder: defineSortOrder(),
    },
    {
      title: t('profiles'),
      key: 'profiles',
      width: 452,
      render: (text: IUserProfile[], record: IUser) => (
        <>
          {record.profiles.map((item: IUserProfile) => (
            <Tag key={item.id} color="success">
              {`${item.firstName} ${item.lastName}`}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: t('role'),
      width: 96,
      render: (text: IRole[], record: IUser) => (
        <>
          {record.roles.map((item: IRole) => {
            return <div key={item.name}>{item.name}</div>
          })}
        </>
      ),
      filtered: !!toJS(usersStore.roleFilter),
      filteredValue: !!usersStore.roleFilter
        ? Array.of(toJS(usersStore.roleFilter))
        : undefined,
      filters: [
        {
          text: t('users'),
          value: 'User',
        },
        {
          text: t('admins'),
          value: 'Admin',
        },
      ],
      onFilter: () => true,
      filterMultiple: false,
    },
    {
      title: t('phone_number'),
      dataIndex: 'phoneNumber',
      width: 160,
      render: (text: string) =>
        text !== '' ? parsePhoneNumber(text).formatInternational() : '',
    },
    {
      title: t('email'),
      dataIndex: 'email',
      width: 192,
      ellipsis: true,
    },
    {
      title: t('creation_date'),
      dataIndex: 'createdAt',
      width: 144,
      ellipsis: true,
      render: (text: string) => <>{new Date(text).toLocaleDateString()}</>,
    },
    {
      title: t('options'),
      key: 'options',
      width: 96,
      render: (text: string, record: IUser) => (
        <Switch
          checked={record.blocked}
          checkedChildren={t('blocked')}
          unCheckedChildren={t('active')}
          onChange={() => {
            usersStore.blockUser(toJS(record))
          }}
        />
      ),
    },
  ]

  const pageSizeOptions = ['7', '10', '20']

  const pagination: TablePaginationConfig = {
    defaultCurrent: 1,
    total: usersStore.requestTotal as number,
    position: ['bottomCenter'],
    defaultPageSize: defaultUsersPageSize,
    current: usersStore.currentPage,
    showSizeChanger: true,
    pageSizeOptions,
    pageSize: usersStore.pageSize,
    onShowSizeChange: (current, size) => usersStore.setPageSize(size),
  }

  function defineSortOrder(): TSortOrder {
    switch (usersStore.sortOrder) {
      case 'ASC':
        return 'ascend'
      case 'DESC':
        return 'descend'
      default:
        return undefined
    }
  }

  function addButtonHandler(): void {
    setIsVisible(true)
  }

  function manualSearchHandler(value: string): void {
    if (!form.getFieldError('search').length) {
      usersStore.setSearchString(value)
    } else {
      return
    }
  }

  function autoSearchHandler(): void {
    form
      .validateFields()
      .then(values => {
        if (!form.getFieldError('search').length) {
          usersStore.defineSearchString(values.search)
        } else {
          return
        }
      })
      .catch(error => console.error(error))
  }

  function tableChangeHandler(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ): void {
    let sorterOption: TSorter
    let filterOption
    if (!filters[2]) {
      filterOption = undefined
    } else {
      filterOption = filters[2][0]
    }
    switch (sorter.order as TSortOrder) {
      case undefined:
        sorterOption = undefined
        break
      case 'ascend':
        sorterOption = 'ASC'
        break
      case 'descend':
        sorterOption = 'DESC'
        break
    }
    usersStore.setUsersRequestOptions({
      sorting: sorterOption,
      filter: filterOption as TFilter,
      toPage: pagination.current as number,
      pageSize: pagination.pageSize as number,
    })
  }

  return (
    <div className={styles.container}>
      <PageHeader
        backIcon={<HeaderMenuIcon />}
        onBack={() => {
          settingsStore.toggleMenu()
        }}
        title={t('users_management')}
        subTitle={
          <>
            {t('users_total')} {usersStore.requestTotal}
          </>
        }
        extra={
          <Space size="small">
            <Button
              icon={<UserAddOutlined />}
              className={styles.button}
              onClick={addButtonHandler}
            >
              {t('add_admin')}
            </Button>
            <Form
              form={form}
              name="searchForm"
              initialValues={{ search: usersStore.searchString }}
            >
              <Form.Item
                name="search"
                rules={[
                  {
                    type: 'string',
                    pattern: new RegExp(
                      "^[a-zA-Zא-ת'-]{0,}(?: [a-zA-Zא-ת'-]*){0,3}$"
                    ),
                    max: 40,
                    message: t('error_search'),
                    whitespace: true,
                  },
                ]}
              >
                <Search
                  allowClear
                  placeholder={t('search')}
                  onSearch={manualSearchHandler}
                  onChange={autoSearchHandler}
                  value={usersStore.searchString}
                />
              </Form.Item>
            </Form>
          </Space>
        }
      />
      <Table
        bordered
        size="small"
        scroll={{ y: '72vh' }}
        dataSource={usersStore.users}
        columns={tableColumns}
        rowKey={record => record.id + ''}
        pagination={pagination}
        onChange={tableChangeHandler}
      />
      <AddAdminDialog isVisible={isVisible} setIsVisible={setIsVisible} />
    </div>
  )
}

export default observer(Users)
