import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { Table, Tag, Input, PageHeader, Tooltip, Form } from 'antd'
import { TablePaginationConfig, ColumnsType, TableProps } from 'antd/lib/table'
import { useTranslation } from 'react-i18next'
import {
  IMessage,
  TMessageType,
  TMessageStatus,
  TSorter,
  TSortOrder,
} from 'models'

import MessageForm from './MessageForm'

import HeaderMenuIcon from 'components/HeaderMenuIcon'

import { useStore } from 'stores'

import styles from './styles.module.scss'
import { defaultMessagesPageSize, messageTypes } from 'config'

const { Search } = Input

function Messages(): React.ReactElement {
  const { settingsStore, messagesStore } = useStore()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isModalVisible, setModalVisible] = useState<boolean>(false)

  const tableColumns: ColumnsType<IMessage> = [
    {
      title: t('user'),
      width: 160,
      dataIndex: 'userFullName',
      ellipsis: {
        showTitle: false,
      },
      render: (text: string, record: IMessage) => (
        <Link to={`/users/${record.userId}`} className={styles.link}>
          <div>
            <Tooltip placement="topLeft" title={<>{text}</>}>
              <span>{text}</span>
            </Tooltip>
          </div>
        </Link>
      ),
    },
    {
      title: t('type'),
      width: 96,
      dataIndex: 'type',
      render: (text: TMessageType) => (
        <>{text[0].toUpperCase() + text.slice(1)}</>
      ),
      filtered: !!toJS(messagesStore.typeFilter),
      filteredValue: !!messagesStore.typeFilter
        ? messagesStore.typeFilter
        : null,
      filters: messageTypes.map(item => ({
        text: t(item),
        value: item,
      })),
      onFilter: () => true,
      filterMultiple: true,
    },
    {
      title: t('title'),
      width: 160,
      dataIndex: 'title',
      ellipsis: true,
    },
    {
      title: t('text'),
      dataIndex: 'text',
      width: 'auto',
      ellipsis: {
        showTitle: false,
      },
      render: (text: string) => (
        <div className={styles.button}>
          <Tooltip placement="topLeft" title={<>{text}</>}>
            {text}
          </Tooltip>
        </div>
      ),
      onCell: (record: IMessage) => {
        return {
          onClick: () => {
            messagesStore.setEditingMessage(record)
            setModalVisible(true)
          },
        }
      },
    },
    {
      title: t('status'),
      width: 96,
      dataIndex: 'status',
      render: (text: TMessageStatus) => (
        <Tag
          color={
            text === 'new'
              ? 'yellow'
              : text === 'accepted'
              ? 'gray'
              : text === 'resolved'
              ? 'green'
              : 'red'
          }
        >
          {text}
        </Tag>
      ),
      filtered: !!toJS(messagesStore.statusFilter),
      filteredValue: !!messagesStore.statusFilter
        ? messagesStore.statusFilter
        : null,
      filters: [
        {
          text: 'New',
          value: 'new',
        },
        {
          text: 'Accepted',
          value: 'accepted',
        },
        {
          text: 'Resolved',
          value: 'resolved',
        },
        {
          text: 'Rejected',
          value: 'rejected',
        },
      ],
      onFilter: () => true,
      filterMultiple: true,
    },
    {
      title: t('message_creation_date'),
      width: 144,
      dataIndex: 'createdOn',
      ellipsis: true,
      render: (text: string) => <>{new Date(text).toLocaleDateString()}</>,
      sorter: true,
      sortOrder: defineSortOrder(),
    },
    {
      title: t('message_update_date'),
      width: 144,
      dataIndex: 'modifiedOn',
      ellipsis: true,
      render: (text: string) => <>{new Date(text).toLocaleDateString()}</>,
    },
  ]

  function manualSearchHandler(value: string): void {
    if (!form.getFieldError('search').length) {
      messagesStore.setSearchString(value)
    } else {
      return
    }
  }

  function autoSearchHandler(): void {
    form
      .validateFields()
      .then(values => {
        if (!form.getFieldError('search').length) {
          messagesStore.defineSearchString(values.search)
        } else {
          return
        }
      })
      .catch(error => console.error(error))
  }

  const pageSizeOptions = ['7', '10', '20']

  const pagination: TablePaginationConfig = {
    defaultCurrent: 1,
    total: messagesStore.messagesTotal,
    position: ['bottomCenter'],
    defaultPageSize: defaultMessagesPageSize,
    current: messagesStore.currentPage,
    showSizeChanger: true,
    pageSizeOptions,
    pageSize: messagesStore.pageSize,
    onShowSizeChange: (current, size) => messagesStore.setPageSize(size),
  }

  function defineSortOrder(): TSortOrder {
    switch (messagesStore.dateSorting) {
      case 'ASC':
        return 'ascend'
      case 'DESC':
        return 'descend'
      default:
        return undefined
    }
  }

  const tableChangeHandler: TableProps<IMessage>['onChange'] = (
    pagination: TablePaginationConfig,
    filters: { type?: null | TMessageType[]; status?: null | TMessageStatus[] },
    sorter: any
  ) => {
    let sorterOption: TSorter
    switch (sorter.order as TSortOrder) {
      case 'ascend':
        sorterOption = 'ASC'
        break
      case 'descend':
        sorterOption = 'DESC'
        break
      default:
        sorterOption = undefined
    }
    messagesStore.setTableOptions({
      typeFilter: filters.type || null,
      dateSorting: sorterOption,
      statusFilter: filters.status || null,
      toPage: pagination.current as number,
      pageSize: pagination.pageSize as number,
    })
  }

  return (
    <div className={styles.container}>
      <PageHeader
        backIcon={<HeaderMenuIcon />}
        onBack={() => {
          settingsStore.toggleMenu()
        }}
        title={t('messages')}
        subTitle={
          <>
            {`${t('messages_total')} ${messagesStore.messagesTotal}, ${t(
              'messages_new'
            )} ${messagesStore.messagesNew}`}
          </>
        }
        extra={
          <Form
            form={form}
            name="userSearchForm"
            initialValues={{ search: messagesStore.userFilter }}
          >
            <Form.Item
              name="search"
              rules={[
                {
                  type: 'string',
                  pattern: new RegExp(
                    "^[a-zA-Zא-ת'-]{0,}(?: [a-zA-Zא-ת'-]*){0,3}$"
                  ),
                  max: 40,
                  message: t('error_search'),
                  whitespace: true,
                },
              ]}
            >
              <Search
                allowClear
                placeholder={t('search')}
                onSearch={manualSearchHandler}
                onChange={autoSearchHandler}
                value={messagesStore.userFilter}
              />
            </Form.Item>
          </Form>
        }
      />
      <Table
        bordered
        size="small"
        scroll={{ y: '72vh' }}
        dataSource={messagesStore.messagesToShow}
        columns={tableColumns}
        rowKey={record => record.id + ''}
        onChange={tableChangeHandler}
        pagination={pagination}
      />
      <MessageForm isVisible={isModalVisible} setVisible={setModalVisible} />
    </div>
  )
}

export default observer(Messages)
