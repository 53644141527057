import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { Typography, Layout, Col, Row, Avatar, Spin, Space } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import classNames from 'classnames/bind'
import { appStoreLink, playMarketLink } from 'config'

// import Followers from './Followers'

import { useStore } from 'stores'

import styles from './styles.module.scss'
import candle from 'sources/images/candle.svg'
import circlesIcon from 'sources/images/circlesIcon.svg'
import mediaIcon from 'sources/images/mediaIcon.svg'
import memoriesIcon from 'sources/images/memoriesIcon.svg'
import musicIcon from 'sources/images/musicIcon.svg'
import timelineIcon from 'sources/images/timelineIcon.svg'
import appStoreIcon from 'sources/images/appStoreIcon.svg'
import playMarketIcon from 'sources/images/playMarketIcon.svg'

const { Content, Footer } = Layout

const getMobileVh = (): void => {
  let mvh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--mvh', `${mvh}px`)
}

function GuestPage(): React.ReactElement {
  const { usersStore } = useStore()
  const { profileId } = useParams()
  const profile = profileId ? +profileId : 1
  useEffect(() => {
    usersStore.getGuestProfile(profile)
    // usersStore.getFollowers(profile)
  }, [])
  useEffect(() => {
    getMobileVh()
    window.addEventListener('resize', getMobileVh)
    return () => window.removeEventListener('resize', getMobileVh)
  }, [])

  const cn = classNames.bind(styles)

  return (
    <div className={styles.background}>
      <Layout className={styles.layout}>
        <Content className={styles.container}>
          {usersStore.guestProfile ? (
            <>
              <div className={styles.candleLineWrapper}>
                <Row justify="end" className={styles.candleLine}>
                  <Col>
                    <Row>
                      <img src={candle} alt="memorial candle" />
                      <svg width="106" height="100" viewBox="0 0 106 100">
                        <defs>
                          <filter
                            id="Path_631"
                            x="0"
                            y="0"
                            width="106"
                            height="100"
                            filterUnits="userSpaceOnUse"
                          >
                            <feOffset dy="3" />
                            <feGaussianBlur stdDeviation="3" result="blur" />
                            <feFlood flood-color="#d0cce1" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                          </filter>
                        </defs>
                        <g
                          id="Group_2415"
                          data-name="Group 2415"
                          transform="translate(-310.498 -169.447)"
                        >
                          <g
                            transform="matrix(1, 0, 0, 1, 310.5, 169.45)"
                            filter="url(#Path_631)"
                          >
                            <path
                              id="Path_631-2"
                              data-name="Path 631"
                              d="M-61.79-121.774c24.323,0,44.041-19.43,44.041-43.4s-19.718-43.4-44.041-43.4-44.041,19.43-44.041,43.4,19.718,43.4,44.041,43.4"
                              transform="translate(114.83 214.57)"
                              fill="#fff"
                            />
                          </g>
                          <text
                            id="_54"
                            data-name="54"
                            transform="translate(348.957 222.722)"
                            fill="#5037b4"
                            font-size="18"
                            text-anchor="middle"
                            x="14"
                          >
                            {usersStore.guestProfile.daysToMemorial ?? 0}
                          </text>
                          <path
                            id="Path_633"
                            data-name="Path 633"
                            d="M-68.958-5.281H-70.52V-6.1A2.241,2.241,0,0,0-72.743-8.35,2.241,2.241,0,0,0-74.966-6.1v.815H-99.28V-6.1A2.241,2.241,0,0,0-101.5-8.35,2.241,2.241,0,0,0-103.727-6.1v.815h-1.561a3.767,3.767,0,0,0-3.737,3.788V32.261a3.767,3.767,0,0,0,3.737,3.788h36.33a3.767,3.767,0,0,0,3.737-3.788V-1.494a3.767,3.767,0,0,0-3.737-3.788M-73.547-6.1a.811.811,0,0,1,.8-.815.811.811,0,0,1,.8.815v3.069a.811.811,0,0,1-.8.815.811.811,0,0,1-.8-.815Zm-28.761,0a.811.811,0,0,1,.8-.815.81.81,0,0,1,.8.815v3.069a.81.81,0,0,1-.8.815.811.811,0,0,1-.8-.815Zm-2.98,2.254h1.561v.815A2.241,2.241,0,0,0-101.5-.774,2.241,2.241,0,0,0-99.28-3.028v-.815h24.315v.815A2.241,2.241,0,0,0-72.743-.774,2.241,2.241,0,0,0-70.52-3.028v-.815h1.561a2.336,2.336,0,0,1,2.318,2.349V.856h-40.966V-1.494a2.337,2.337,0,0,1,2.318-2.349m36.33,38.454h-36.33a2.337,2.337,0,0,1-2.318-2.349V2.294h40.966V32.261a2.336,2.336,0,0,1-2.318,2.349"
                            transform="translate(449.423 195.995)"
                            fill="#63549c"
                          />
                          <g
                            id="Group_2342"
                            data-name="Group 2342"
                            transform="translate(236.368 281.262)"
                          >
                            <text
                              id="Days"
                              transform="translate(111.63 -33.071)"
                              fill="#503e97"
                              font-size="14"
                            >
                              <tspan x="-2" y="0">
                                Days
                              </tspan>
                            </text>
                          </g>
                        </g>
                      </svg>
                    </Row>
                    <Row justify="center" className={styles.candleText}>
                      Next Memorial Day
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className={styles.guestProfile}>
                {/* <Followers /> */}
                <div className={styles.info}>
                  <Avatar
                    src={usersStore.guestProfile?.avatarThumbnail}
                    icon={<UserOutlined />}
                    size={128}
                  />
                  <Typography.Title level={4}>
                    {usersStore.guestProfile?.firstName}{' '}
                    {usersStore.guestProfile?.lastName}
                  </Typography.Title>
                  <Typography.Text className={styles.infoItem}>
                    {`${new Date(
                      usersStore.guestProfile?.birthday as Date
                    ).getFullYear()} - ${new Date(
                      usersStore.guestProfile?.passedAway as Date
                    ).getFullYear()}`}
                  </Typography.Text>
                  <div className={cn({ icons: true, iconsCircles: true })}>
                    CIRCLES
                    <img src={circlesIcon} alt="circles icon" />
                  </div>
                  <div className={cn({ icons: true, iconsMemories: true })}>
                    <img src={memoriesIcon} alt="memories icon" />
                    MEMORIES
                  </div>
                  <div className={cn({ icons: true, iconsMusic: true })}>
                    <img src={musicIcon} alt="music icon" />
                    MUSIC
                  </div>
                  <div className={cn({ icons: true, iconsMedia: true })}>
                    MEDIA
                    <img src={mediaIcon} alt="media icon" />
                  </div>
                  <div className={cn({ icons: true, iconsTimeline: true })}>
                    <img src={timelineIcon} alt="timeline icon" />
                    TIMELINE
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Spin size="large" />
          )}
        </Content>
        <Footer className={styles.footer}>
          <Space size="large">
            <Typography.Link href={appStoreLink} target="_blank">
              <img
                src={appStoreIcon}
                className={styles.linkIcon}
                alt="app store icon"
              />
            </Typography.Link>
            <Typography.Link href={playMarketLink} target="_blank">
              <img
                src={playMarketIcon}
                className={styles.linkIcon}
                alt="play market icon"
              />
            </Typography.Link>
          </Space>
        </Footer>
      </Layout>
    </div>
  )
}

export default observer(GuestPage)
