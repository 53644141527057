import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Input, Button, Row, Form, Col } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { TCategoriesNames, IStore } from 'models'

import { useStore } from 'stores'

function SettingsList({
  settingsName,
}: {
  settingsName: TCategoriesNames
}): React.ReactElement {
  const { t } = useTranslation()
  const { settingsStore } = useStore()
  const [isEditing, setIsEditing] = useState<boolean>(false)

  function toggleEditing(): void {
    setIsEditing(true)
  }

  function formSaveHandler(values: IStore): void {
    settingsStore.updateCategories(settingsName, values)
    setIsEditing(false)
  }

  return (
    <Form
      name="picCategories"
      onFinish={formSaveHandler}
      wrapperCol={{ sm: 24, xl: 16 }}
    >
      <Row justify="start">
        <Col sm={24} xl={16}>
          <Row justify="space-between">
            <Col>
              <Button onClick={toggleEditing}>
                <EditOutlined />
              </Button>
            </Col>
            <Form.Item>
              <Button type="primary" disabled={!isEditing} htmlType="submit">
                {t('save')}
              </Button>
            </Form.Item>
          </Row>
        </Col>
      </Row>
      {settingsStore.settings[settingsName].map(item => (
        <Form.Item
          key={item.name}
          rules={[
            { required: true, whitespace: true, message: t('error_category') },
          ]}
          name={item.id}
          initialValue={item.name}
        >
          <Input disabled={!isEditing} bordered />
        </Form.Item>
      ))}
    </Form>
  )
}

export default observer(SettingsList)
