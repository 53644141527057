import {
  api,
  profilesFollowersEndpoint,
  profilesBlockEndpoint,
  profilesPublicEndpoint,
  usersFollowersEndpoint,
} from '../config'
import { IGuestFollower, IUserProfile } from 'models'

export async function blockProfile(profile: IUserProfile): Promise<void> {
  const { data, status } = await api.patch(
    profilesBlockEndpoint + '/' + profile.id,
    {
      blocked: !profile.blocked,
    }
  )
  if (status !== 200) {
    throw new Error(data.message || `Can't patch profile`)
  }
}

export async function getGuestProfile(
  profileId: number
): Promise<IUserProfile> {
  const { data, status } = await api.get(
    profilesPublicEndpoint + '/' + profileId
  )
  if (status !== 200) {
    throw new Error(data.message || `Can't load profile`)
  }
  return data
}

export async function getUsersFollowers(
  profileId: number
): Promise<IGuestFollower[]> {
  const { data, status } = await api.get(
    `${usersFollowersEndpoint}?profileId=${profileId}&skip=0&take=99`
  )
  if (status !== 200) {
    throw new Error(data.message || `Can't load profile`)
  }
  return data?.data
}

export async function getProfilesFollowers(
  profileId: number
): Promise<IGuestFollower[]> {
  const { data, status } = await api.get(
    `${profilesFollowersEndpoint}?profileId=${profileId}&skip=0&take=99`
  )
  if (status !== 200) {
    throw new Error(data.message || `Can't load profile`)
  }
  return data?.data
}
