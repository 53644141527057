import React from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Avatar, Menu, Card, Row, Col, Descriptions } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { IUserProfile } from 'models'

import DropdownMenu from '../DropdownMenu'

import { useStore } from 'stores'

import styles from './styles.module.scss'

function ProfileCard({ profileId }: { profileId: number }): React.ReactElement {
  const { usersStore } = useStore()
  const { t } = useTranslation()
  const currentProfile = usersStore.currentUser?.profiles.find(
    item => item.id === profileId
  )

  const menu = (
    <Menu>
      <Menu.Item
        key="block"
        onClick={() => usersStore.blockProfile(currentProfile as IUserProfile)}
      >
        {t('block')}
      </Menu.Item>
    </Menu>
  )

  return (
    <Card
      hoverable
      size="small"
      title={`${currentProfile?.firstName} ${
        currentProfile?.middleName ? currentProfile?.middleName : ''
      } ${currentProfile?.lastName}`}
      bordered={false}
      className={styles.profile_card}
      extra={<DropdownMenu key="more" menu={menu} />}
    >
      <Row justify="center">
        <Col>
          <Avatar
            src={currentProfile?.avatarThumbnail}
            icon={<UserOutlined />}
            size={80}
          />
        </Col>
      </Row>
      <Descriptions size="small" column={1} bordered>
        <Descriptions.Item label={t('full_name')}>
          {`${currentProfile?.firstName} ${currentProfile?.lastName}`}
        </Descriptions.Item>
        <Descriptions.Item label={t('description')}>
          {currentProfile?.biography}
        </Descriptions.Item>
        <Descriptions.Item label={t('years_of_living')}>
          {`${new Date(
            currentProfile?.birthday as Date
          ).getFullYear()} - ${new Date(
            currentProfile?.passedAway as Date
          ).getFullYear()}`}
        </Descriptions.Item>
        <Descriptions.Item label={t('is_alive')}>
          {currentProfile?.isAlive ? t('alive') : t('dead')}
        </Descriptions.Item>
        <Descriptions.Item label={t('birth_date')}>
          {new Date(currentProfile?.birthday as Date).toLocaleDateString()}
        </Descriptions.Item>
        <Descriptions.Item label={t('death_date')}>
          {new Date(currentProfile?.passedAway as Date).toLocaleDateString()}
        </Descriptions.Item>
        <Descriptions.Item label={t('gender')}>
          {currentProfile?.gender}
        </Descriptions.Item>
        <Descriptions.Item label={t('address')}>
          {`${currentProfile?.country} ${currentProfile?.state} ${currentProfile?.city}`}
        </Descriptions.Item>
        <Descriptions.Item label={t('acquisition')}>
          {currentProfile?.relationshipOfCreatingUser}
        </Descriptions.Item>
        {currentProfile?.blocked && (
          <Descriptions.Item label={t('status')}>
            {currentProfile?.blocked ? t('blocked') : ''}
          </Descriptions.Item>
        )}
        {currentProfile?.followerStatistics && (
          <Descriptions.Item label={t('follower_statistics')}>
            {currentProfile.followerStatistics.Family &&
              currentProfile.followerStatistics.Family.total > 0 && (
                <Row justify="center">
                  <Col span={10}>{t('family')}</Col>
                  <Col span={10}>
                    {currentProfile.followerStatistics.Family.total}
                  </Col>
                </Row>
              )}
            {currentProfile.followerStatistics.Work &&
              currentProfile.followerStatistics.Work.total > 0 && (
                <Row justify="center">
                  <Col span={10}>{t('work')}</Col>
                  <Col span={10}>
                    {currentProfile.followerStatistics.Work.total}
                  </Col>
                </Row>
              )}
            {currentProfile.followerStatistics.School &&
              currentProfile.followerStatistics.School.total > 0 && (
                <Row justify="center">
                  <Col span={10}>{t('school')}</Col>
                  <Col span={10}>
                    {currentProfile.followerStatistics.School.total}
                  </Col>
                </Row>
              )}
            {currentProfile.followerStatistics.Hobby &&
              currentProfile.followerStatistics.Hobby.total > 0 && (
                <Row justify="center">
                  <Col span={10}>{t('hobby')}</Col>
                  <Col span={10}>
                    {currentProfile.followerStatistics.Hobby.total}
                  </Col>
                </Row>
              )}
            {currentProfile.followerStatistics.Other &&
              currentProfile.followerStatistics.Other.total > 0 && (
                <Row justify="center">
                  <Col span={10}>{t('other')}</Col>
                  <Col span={10}>
                    {currentProfile.followerStatistics.Other.total}
                  </Col>
                </Row>
              )}
            {currentProfile.followerStatistics.total && (
              <Row className={styles.total} justify="center">
                <Col span={10}>{t('total')}</Col>
                <Col span={10}>{currentProfile.followerStatistics.total}</Col>
              </Row>
            )}
          </Descriptions.Item>
        )}
      </Descriptions>
    </Card>
  )
}

export default observer(ProfileCard)
