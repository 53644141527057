import { api } from '../config'
import { ICategory } from 'models'

export async function getCategoriesList(
  categoryEndpoint: string
): Promise<ICategory[]> {
  const { data, status } = await api.get(categoryEndpoint)
  if (status !== 200) {
    throw new Error(data.message || 'There was error fetching categories')
  }
  return data
}

export async function saveCategoriesList(
  categoryEndpoint: string,
  categories: ICategory[]
): Promise<ICategory[]> {
  const { data, status } = await api.patch(categoryEndpoint, categories)
  if (status !== 200) {
    throw new Error(data.message || `Can't patch categories`)
  }
  return data
}
