import React from 'react'
import { observer } from 'mobx-react-lite'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'

import { useStore } from 'stores'

function HeaderMenuIcon(): React.ReactElement {
  const { settingsStore } = useStore()

  return settingsStore.settings.isMenuCollapsed ? (
    <MenuUnfoldOutlined />
  ) : (
    <MenuFoldOutlined />
  )
}

export default observer(HeaderMenuIcon)
