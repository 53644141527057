import {
  api,
  loginEndpoint,
  resetEndpoint,
  usersEndpoint,
  userEndpoint,
  addAdminEndpoint,
  userInfoEndpoint,
} from '../config'
import {
  ICredentials,
  IUser,
  IAdmin,
  ILoginResponseDTO,
  IUsersResponseDTO,
} from '../models'

export async function login(
  credentials: ICredentials
): Promise<ILoginResponseDTO> {
  const { data, status } = await api.post(loginEndpoint, credentials)
  if (status !== 201) {
    throw new Error(data.message[0])
  }
  return data
}

export async function addAdmin(admin: IAdmin): Promise<void> {
  const { status, data } = await api.post(addAdminEndpoint, admin)
  if (status !== 201) {
    let errorCode
    if (typeof data.message === 'string') {
      errorCode = data.message
    } else {
      errorCode = data.message[0].code
    }
    throw new Error(errorCode)
  }
}

export async function resetPassword(email: { email: string }): Promise<void> {
  const { data, status } = await api.post(resetEndpoint, email)
  if (status !== 201) {
    throw new Error(data.message || 'Error resetting password')
  }
}

export async function getUsers(usersRequestOptions: {}): Promise<IUsersResponseDTO> {
  const { data, status } = await api.get(usersEndpoint, usersRequestOptions)
  if (!(status === 200 || status === 304)) {
    throw new Error(data.message || 'Error getting users list')
  }
  return data
}

export async function blockUser(user: IUser): Promise<void> {
  const { data, status } = await api.patch(userEndpoint + '/' + user.id, {
    blocked: !user.blocked,
  })
  if (status !== 200) {
    throw new Error(data.message || 'Error blocking user')
  }
}

export async function fetchUserInfo(userId: number): Promise<IUser> {
  const { data, status } = await api.get(userInfoEndpoint + '/' + userId)
  if (!(status === 200 || status === 304)) {
    throw new Error(data.message || 'Error fetching user info')
  }
  return data
}
