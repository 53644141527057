import React from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Select } from 'antd'
import { TLocaleNameStrict } from 'models'

import { useStore } from 'stores'

import enIcon from 'sources/images/en_icon.png'
import heIcon from 'sources/images/he_icon.png'

const { Option } = Select

function LanguageSelector(): React.ReactElement {
  const { settingsStore } = useStore()
  const { t } = useTranslation()

  function languageSelectHandler(value: TLocaleNameStrict): void {
    settingsStore.setLocale(value)
  }

  return (
    <Select
      key="lang"
      showArrow={false}
      defaultValue={settingsStore.settings.locale}
      bordered={false}
      onChange={value => languageSelectHandler(value)}
    >
      <Option key={1} value="en">
        <img src={enIcon} alt={t('english_selector')} />
      </Option>
      <Option key={2} value="he">
        <img src={heIcon} alt={t('hebrew_selector')} />
      </Option>
    </Select>
  )
}

export default observer(LanguageSelector)
