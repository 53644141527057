import React from 'react'
import { observer } from 'mobx-react-lite'

import LanguageSelector from 'components/LanguageSelector'
import SchemeToggler from 'components/SchemeToggler'

function ConfigPanel(): React.ReactElement {
  return (
    <>
      <SchemeToggler />
      <LanguageSelector />
    </>
  )
}

export default observer(ConfigPanel)
