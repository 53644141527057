import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Link, useLocation } from 'react-router-dom'
import { Menu, Badge, Space } from 'antd'
import {
  LogoutOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  MessageOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

function NavBar(): React.ReactElement {
  const { t } = useTranslation()
  const { settingsStore, messagesStore } = useStore()
  let location = useLocation()
  let [curLocation, setCurLocation] = useState<string[]>(
    location.pathname === '/' ? ['users'] : Array.of(location.pathname.slice(1))
  )

  useEffect(() => {
    setCurLocation(
      location.pathname === '/'
        ? ['users']
        : Array.of(location.pathname.slice(1))
    )
  }, [location])

  function logoutHandler(): void {
    settingsStore.unsetAuth()
  }

  return (
    <div className="navbar_container">
      <Menu theme="dark" selectedKeys={curLocation}>
        <Menu.Item key="users" icon={<TeamOutlined />}>
          <Link to="/users">{t('users_management')}</Link>
        </Menu.Item>
        <Menu.Item key="settings" icon={<UnorderedListOutlined />}>
          <Link to="/settings">{t('list_management')}</Link>
        </Menu.Item>
        <Menu.Item key="messages" icon={<MessageOutlined />}>
          <Link to="/messages">
            <Space>
              {t('messages')}
              {messagesStore.messagesNew > 0 && (
                <Badge count={messagesStore.messagesNew} />
              )}
            </Space>
          </Link>
        </Menu.Item>
        <Menu.Item
          key="logout"
          icon={<LogoutOutlined />}
          danger={true}
          onClick={logoutHandler}
        >
          {t('log_out')}
        </Menu.Item>
      </Menu>
    </div>
  )
}

export default observer(NavBar)
