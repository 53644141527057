import React from 'react'
import { Navigate, RouteProps, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'

import { useStore } from 'stores'

interface IProps {
  children: React.ReactElement
}

export const PrivateRoute = (
  props: RouteProps & IProps
): React.ReactElement => {
  const { settingsStore } = useStore()
  const location = useLocation()

  return settingsStore.settings.isAuth ? (
    props.children
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  )
}

export default observer(PrivateRoute)
