import React from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Dropdown } from 'antd'
import { MoreOutlined } from '@ant-design/icons'

function DropdownMenu({ menu }: { menu: JSX.Element }): React.ReactElement {
  return (
    <Dropdown overlay={menu}>
      <Button>
        <MoreOutlined />
      </Button>
    </Dropdown>
  )
}

export default observer(DropdownMenu)
