import React from 'react'
import { observer } from 'mobx-react'
import { Row, Form, Col, Select, Modal } from 'antd'
import moment from 'moment'
import { t } from 'i18next'

import { useStore } from 'stores'

import styles from './styles.module.scss'

const { Option } = Select

interface IProps {
  isVisible: boolean
  setVisible: (arg: boolean) => void
}

const MessageForm: React.FC<IProps> = ({ isVisible, setVisible }) => {
  const [form] = Form.useForm()
  const { messagesStore } = useStore()

  const cancelHandler = (): void => {
    form.resetFields()
    setVisible(false)
  }

  const okHandler = (): void => {
    form
      .validateFields()
      .then(() => {
        if (form.isFieldsTouched()) {
          const status = form.getFieldValue('status')
          messagesStore.patchMessage(status)
        }
      })
      .catch(info => {
        console.error('Validate Failed:', info)
      })
      .finally(() => {
        form.resetFields()
        setVisible(false)
      })
  }

  return !messagesStore.editingMessage ? null : (
    <Modal
      centered={true}
      maskClosable={false}
      visible={isVisible}
      onOk={okHandler}
      cancelText={t('cancel')}
      onCancel={cancelHandler}
      title={t(messagesStore.editingMessage.type)}
    >
      <Row justify="center" align="middle" className={styles.row}>
        <Col>
          <span>{messagesStore.editingMessage.userFullName}</span>
        </Col>
      </Row>
      <Row justify="center" align="middle" className={styles.row}>
        <Col>{messagesStore.editingMessage.title}</Col>
      </Row>
      <Row justify="center" align="middle" className={styles.row}>
        <Col>{messagesStore.editingMessage.text}</Col>
      </Row>
      <Row
        gutter={[16, 16]}
        justify="center"
        align="middle"
        className={styles.row}
      >
        <Col>{`${t('message_creation_date')}: ${moment(
          messagesStore.editingMessage.createdOn
        ).format('DD.MM.YYYY HH-mm')}`}</Col>
      </Row>
      <Form
        form={form}
        name="basic"
        autoComplete="off"
        fields={[
          {
            name: ['status'],
            value:
              messagesStore.editingMessage.status[0].toUpperCase() +
              messagesStore.editingMessage.status.slice(1),
          },
        ]}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 6 }}
      >
        <Form.Item name="status" label={t('status')} validateStatus="success">
          <Select>
            <Option value={'resolved'}>{t('resolved')}</Option>
            <Option value={'rejected'}>{t('rejected')}</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default observer(MessageForm)
