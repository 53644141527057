import axios from 'axios'
import { ICategories } from './models'

export const loginEndpoint = '/auth/login'
export const addAdminEndpoint = '/auth/admin'
export const resetEndpoint = '/auth/restore_password'
export const usersEndpoint = '/users/user_management'
export const userEndpoint = '/users'
export const userInfoEndpoint = '/users/details'
export const profilesBlockEndpoint = '/profiles/block'
export const profilesEndpoint = '/profiles'
export const profilesPublicEndpoint = '/profiles/public'
export const usersFollowersEndpoint = '/circles/public/users-followers'
export const profilesFollowersEndpoint = '/circles/public/profiles-followers'
export const massagesEndpoint = '/user-submissions'

export const minAutoSearchLength = 3
export const defaultUsersPageSize = 20
export const defaultMessagesPageSize = 20
export const playMarketLink = process.env.REACT_APP_PLAYMARKET_LINK
export const appStoreLink = process.env.REACT_APP_APPSTORE_LINK

export const settingsLists: ICategories[] = [
  { title: 'familyMembers', endpoint: '/family-members' },
  { title: 'friends', endpoint: '/friend-categories' },
  { title: 'circles', endpoint: '/circle-names' },
  { title: 'religion', endpoint: '/religion' },
  { title: 'picCategories', endpoint: '/pic-categories' },
]

export const ErrorsMessages = {
  DUPLICATE_ERROR: 'duplicateError',
  EMAIL_LENGTH_EXCEEDED_100: 'emailLengthError',
  INVALID_EMAIL_FORMAT: 'emailFormatError',
  PHONE_NUMBER_LENGTH_EXCEEDED_13: 'phoneLengthError',
  'PASSWORD IS RESTORING': 'passwordRestoringError',
  USER_NOT_FOUND: 'userNotFoundError',
}

export const messageTypes = <const>['contact', 'abuse', 'deletion']

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  validateStatus: null,
})
