import React from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { PageHeader, Collapse, BackTop } from 'antd'
import { settingsLists } from 'config'

import SettingsList from 'components/SettingsList'
import HeaderMenuIcon from 'components/HeaderMenuIcon'

import { useStore } from 'stores'

const { Panel } = Collapse

function Settings(): React.ReactElement {
  const { t } = useTranslation()
  const { settingsStore } = useStore()

  return (
    <div>
      <BackTop />
      <PageHeader
        backIcon={<HeaderMenuIcon />}
        onBack={() => {
          settingsStore.toggleMenu()
        }}
        title={t('list_management')}
      />
      <Collapse bordered={false}>
        {settingsLists.map(item => {
          return (
            <Panel header={t(item.title)} key={item.title}>
              <SettingsList settingsName={item.title} />
            </Panel>
          )
        })}
      </Collapse>
    </div>
  )
}

export default observer(Settings)
