import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import {
  Avatar,
  Menu,
  PageHeader,
  Card,
  Row,
  Col,
  Descriptions,
  Divider,
  BackTop,
  Spin,
  Space,
  Statistic,
} from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { parsePhoneNumber } from 'libphonenumber-js'
import { IUserProfile, IUser } from 'models'

import DropdownMenu from 'components/DropdownMenu'
import ProfileCard from 'components/ProfileCard'
import HeaderMenuIcon from 'components/HeaderMenuIcon'

import { useStore } from 'stores'

import styles from './styles.module.scss'

function User(): React.ReactElement {
  const store = useStore()
  const { usersStore, settingsStore } = store
  const { t } = useTranslation()
  const { userId } = useParams<{ userId: string }>()
  useEffect(() => {
    if (!usersStore.infoHasUpdated) {
      const user = userId ? +userId : 1
      usersStore.getUserInfo(user)
      return () => {
        usersStore.unsetInfoHasUpdated()
      }
    }
  }, [])

  const menu = (
    <Menu>
      <Menu.Item
        key="block"
        onClick={() => usersStore.blockUser(usersStore.currentUser as IUser)}
      >
        {t('block')}
      </Menu.Item>
    </Menu>
  )

  return !usersStore.infoHasUpdated ? (
    <div className={styles.container}>
      <Spin size="large" />
    </div>
  ) : (
    <div className={styles.container}>
      <BackTop />
      <PageHeader
        backIcon={<HeaderMenuIcon />}
        onBack={() => {
          settingsStore.toggleMenu()
        }}
        title={`${t('user')} ${usersStore.currentUser?.firstName} ${
          usersStore.currentUser?.lastName
        }`}
        subTitle={
          usersStore.currentUser?.profiles
            ? `${usersStore.currentUser?.profiles?.length} ${t('profiles')}`
            : null
        }
      />
      <Row>
        <Card
          size="small"
          hoverable
          title={`${usersStore.currentUser?.firstName} ${usersStore.currentUser?.lastName}`}
          extra={<DropdownMenu key="more" menu={menu} />}
        >
          <Row gutter={16}>
            <Col>
              <Avatar
                src={usersStore.currentUser?.avatarThumbnail}
                icon={<UserOutlined />}
                size={160}
              />
            </Col>
            <Col>
              <Descriptions size="small" column={1} bordered>
                <Descriptions.Item label={t('email')}>
                  {usersStore.currentUser?.email}
                </Descriptions.Item>

                <Descriptions.Item label={t('phone_number')}>
                  {usersStore.currentUser &&
                  usersStore.currentUser.phoneNumber !== ''
                    ? parsePhoneNumber(
                        usersStore.currentUser?.phoneNumber + ''
                      ).formatInternational()
                    : 'No phone number'}
                </Descriptions.Item>
                {(usersStore.currentUser?.blocked ||
                  usersStore.currentUser?.isRestoringPassword) && (
                  <Descriptions.Item label={t('status')}>
                    <Space size="small">
                      {usersStore.currentUser?.blocked ? t('blocked') : ''}
                      {usersStore.currentUser?.isRestoringPassword
                        ? t('restoring_password')
                        : ''}
                    </Space>
                  </Descriptions.Item>
                )}
                <Descriptions.Item label={t('last_active')}>
                  {new Date(
                    usersStore.currentUser?.updatedAt + ''
                  ).toLocaleString()}
                </Descriptions.Item>
                <Descriptions.Item label={t('creation_date')}>
                  {new Date(
                    usersStore.currentUser?.createdAt + ''
                  ).toLocaleString()}
                </Descriptions.Item>
                {usersStore.currentUser?.totalMediaSize && (
                  <Descriptions.Item label={t('media_size')}>
                    <Statistic
                      value={usersStore.currentUser?.totalMediaSize}
                      precision={2}
                      valueStyle={{ fontSize: 'unset' }}
                    />
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Col>
          </Row>
        </Card>
      </Row>
      {usersStore.currentUser?.profiles &&
      usersStore.currentUser?.profiles?.length > 0 ? (
        <>
          <Divider orientation="left">{t('profiles')}</Divider>
          <Row gutter={[16, 16]}>
            {usersStore.currentUser.profiles.map((item: IUserProfile) => {
              return (
                <Col key={item.id}>
                  <ProfileCard profileId={item.id} />
                </Col>
              )
            })}
          </Row>
        </>
      ) : null}
    </div>
  )
}

export default observer(User)
