import React from 'react'
import { observer } from 'mobx-react-lite'
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'
import { Input, Modal, Form } from 'antd'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

function AddAdminDialog({
  isVisible,
  setIsVisible,
}: {
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
}): React.ReactElement {
  const { usersStore } = useStore()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  function handleAddAdmin(): void {
    form
      .validateFields()
      .then(values => {
        setIsVisible(false)
        usersStore.addAdmin({
          phoneNumber: parsePhoneNumber('+' + values.phone).number,
          email: values.email,
          firstName: values.name.split(' ')[0],
          lastName: values.name.split(' ').slice(1).join(' '),
        })
        form.resetFields()
      })
      .catch(error => console.error(error))
  }

  function handleCancel(): void {
    form.resetFields()
    setIsVisible(false)
  }

  const formInitialValues = {
    name: '',
    email: '',
    phone: '',
  }

  return (
    <Modal
      centered={true}
      maskClosable={false}
      title={<>{t('admin_add')}</>}
      visible={isVisible}
      onOk={handleAddAdmin}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        autoComplete="off"
        initialValues={formInitialValues}
        validateMessages={{
          default: '',
          required: '',
        }}
      >
        <Form.Item
          label={<>{t('full_name')}</>}
          name="name"
          rules={[
            {
              required: true,
              message: t('error_name'),
            },
            {
              type: 'string',
              message: t('error_name'),
            },
            {
              pattern: new RegExp(
                "^[A-Zא-ת][a-zA-Zא-ת'-]{0,}(?: [A-Zא-ת][a-zA-Zא-ת'-]*){1,3}$"
              ),
              message: t('error_name'),
            },
            {
              max: 40,
              message: t('error_name'),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={<>{t('email')}</>}
          name="email"
          rules={[
            {
              type: 'email',
              message: t('error_email'),
            },
            {
              required: true,
              message: t('error_email'),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={<>{t('phone_number')}</>}
          name="phone"
          rules={[
            {
              required: true,
              message: t('error_phone'),
            },
            {
              pattern: new RegExp('^[- ()0-9]{12,18}$'),
              message: t('error_phone_pattern'),
            },
            {
              validator: (_, value) => {
                let phoneNumber = parsePhoneNumber('+' + value)
                if (!value || isValidPhoneNumber(phoneNumber.number)) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error(t('error_phone_invalid')))
              },
            },
          ]}
        >
          <Input
            placeholder={t('phone_description')}
            prefix="+"
            maxLength={18}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default observer(AddAdminDialog)
